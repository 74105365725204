<template>
  <b-card>
    <b-row>
      <b-col cols="12"
             class="d-flex align-items-center"
             style="gap:10px;"
      >
        <h5>Select an input type</h5>
        <div v-if="!disableInputOption"
             class="d-flex input-selection-types"
        >
          <div v-b-tooltip.hover="`Input type text`"
               class="input-selection-card"
               :class="inputType=='text' && 'selected'"
               @click="inputType='text'"
          >
            <feather-icon icon="TypeIcon"
                          size="20"
            />
          </div>
          <div v-b-tooltip.hover="`Input type audio`"
               class="input-selection-card"
               :class="inputType=='audio' && 'selected'"
               @click="inputType='audio'"
          >
            <feather-icon icon="MicIcon"
                          size="20"
            />
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row v-if="showLangSelector"
           class="my-2"
    >
      <b-col cols="12"
             class="d-flex align-items-center"
      >
        <h5 class="mr-2">
          Select input Language:
        </h5>
        <b-form-select v-model="selectedInputLanguage"
                       :options="languageOptions"
        />
      </b-col>
      <b-col cols="12"
             class="d-flex align-items-center"
      >
        <h5 class="mr-2">
          Select output Language:
        </h5>
        <b-form-select v-model="selectedLanguage"
                       :options="languageOptions"
        />
      </b-col>
    </b-row>

    <h5 class="my-1">
      Please select your character.
    </h5>
    <b-row>
      <b-col
        v-for="(character,key) in characters "
        :key="key"
        lg="4"
        sm="6"
      >
        <div
          class="character-image d-flex justify-content-center align-items-center"
          :class="{'selected-character':key===selectedCharacter}"
        >
          <b-form-radio
            v-model="selectedCharacter"
            name="selected-character"
            class="p-0"
            style="margin:5px;"
            :disabled="isProcessing"
            :value="key"
          >
            <img :src="`${require(`@/assets/images/chatbot/${character}`)}`"
                 class="img-fluid"
            >
          </b-form-radio>
        </div>
      </b-col>
    </b-row>

    <b-row v-if="showHistory"
           class="mb-2"
    >
      <b-col cols="12">
        <h5>Chat History</h5>
        <json-editor-vue v-model="history"
                         :read-only="true"
                         :options="{
                           mode: 'code',
                           mainMenuBar: false,
                           statusBar: false,
                           navigationBar: false,
                         }"
        />
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { IDLE } from '@/const/chatbot'
import {
  BRow, BCol, BFormRadio, BCard, BFormSelect,
} from 'bootstrap-vue'
import JsonEditorVue from 'json-editor-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormRadio,
    BCard,
    BFormSelect,
    JsonEditorVue,
  },
  props: {
    disableInputOption: {
      type: Boolean,
      default: false,
    },
    showLangSelector: {
      type: Boolean,
      default: false,
    },
    showHistory: {
      type: Boolean,
      default: false,
    },
    history: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      characters: IDLE,
      isProcessing: false,
      languageOptions: [
        { value: null, text: 'Select Language' },
        { value: 'english', text: 'English' },
        { value: 'korean', text: 'Korean' },
        { value: 'japanese', text: 'Japanese' },
        { value: 'vietnamese', text: 'Vietnamese' },
        { value: 'nepali', text: 'Nepali' },
      ],
    }
  },

  computed: {
    selectedCharacter: {
      get() {
        return this.$store.getters['appConfig/currentChatbotCharacter']
      },
      set(val) {
        this.$store.commit('appConfig/SET_CURRENT_CHATBOT_CHARACTER', val)
      },
    },
    inputType: {
      get() {
        return this.$store.getters['appConfig/currentChatbotInputType']
      },
      set(val) {
        this.$store.commit('appConfig/SET_CURRENT_CHATBOT_INPUT_TYPE', val)
      },
    },
    selectedLanguage: {
      get() {
        return this.$store.getters['appConfig/currentChatbotLanguage']
      },
      set(val) {
        this.$store.commit('appConfig/SET_CURRENT_CHATBOT_LANGUAGE', val)
      },
    },
    selectedInputLanguage: {
      get() {
        return this.$store.getters['appConfig/currentChatbotInputLanguage']
      },
      set(val) {
        this.$store.commit('appConfig/SET_CHATBOT_INPUT_LANGUAGE', val)
      },
    },
  },
}
</script>

<style lang="scss">
  .input-selection-types{
    align-items: center;
    justify-content: center;
    gap:15px;

    .input-selection-card{
      width:35px;
      height:35px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #7367f0;
      cursor: pointer;
      color:#7367f0;
      border-radius: 5px;
      &.selected{
        background: #7367f0;
        border-color: #7367f0;
        color:white;
      }
    }
  }
</style>
